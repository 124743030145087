
<template>
  <div id="user-edit-profile">
    <h6 class="mb-3 text-uppercase bg-light p-2">
      Basic Info
    </h6>
    <div class="row g-3">
      <div class="col-md-6">
        <label class="form-label" for="firstName">First name</label>
        <input id="firstName" type="text" class="form-control" v-model="data.first_name"
        :class="{ 'is-invalid': v$.data.first_name.$error }" placeholder="First name" name="first_name" />
        <div v-if="v$.data.first_name.$error"
          class="invalid-feedback">
          <span v-if="v$.data.first_name.required.$message">
            {{ v$.data.first_name.required.$message }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label" for="LastName">Last name</label>
        <input id="LastName" type="text" class="form-control" v-model="data.last_name"
        :class="{ 'is-invalid': v$.data.last_name.$error }"  placeholder="Last Name" name="last_name" />
        <div v-if="v$.data.last_name.$error"
          class="invalid-feedback">
          <span v-if="v$.data.last_name.required.$message">
            {{ v$.data.last_name.required.$message }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label" for="inputEmail">Email Address</label>
        <input id="inputEmail" :disabled="!data.is_verified" :readonly="!data.is_verified"
         label="Email" v-model="data.email" :class="{ 'is-invalid': v$.data.email.$error }"
          type="email" name="email" class="form-control"/>
        <div v-for="(item, index) in v$.data.email.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>  
      </div>
      <div class="col-md-6">
        <label class="form-label d-block">Gender</label>
        <select v-model="data.gender" name="gender"
          class="form-select" :class="{ 'is-invalid': v$.data.gender.$error }">
          <option disabled value="">--select gender--</option>
          <option value="male"> Male </option>
          <option value="female"> Female </option>
          <option value="others"> Others </option>
        </select>
        <div v-for="(item, index) in v$.data.gender.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div> 
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <h5 class="fs-15 mb-1">Change Profile photo</h5>
        <p class="text-muted">Dimension should be 450 x 450 Max: 100KB.</p>
        <input
            class="form-control d-none"
            id="product-image-input"
            type="file"
            ref="updateProfilePhoto" @change="fileUploadReader"
            accept="image/*"
          />
        <div>
          <span v-if="profileImg" class="">
            <button class="btn me-3 mb-2 btn-sm btn-outline-primary"
            >Selected file: {{profileImg.name}}</button>
            <button @click="resetForm()" class="btn mb-2 btn-sm btn-danger"><i class="bx bx-x"></i></button>
          </span>
          <button v-else  class="btn btn-sm btn-outline-primary" @click="$refs.updateProfilePhoto.click()">Browse file</button>
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-12">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser()" class="btn btn-primary mt-2">
            Save Changes
          </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Others', value: 'others' }
      ],
      data: {
        first_name: "",
        last_name: "",
        email: "",
        gender: "",
      },
      profileImg:null
    }
  },
  validations: {
    data:{
      first_name: {
        required: helpers.withMessage("First name is required", required),
      },
      last_name: {
        required: helpers.withMessage("Last name is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      gender: {
        required: required,
      },
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.activeUser
    }
  },
  methods: {
    updateUser() {
      this.v$.$validate().then(result =>{
        if (!result) return;
        const formData = new FormData()
        if(this.profileImg){formData.append('image_file', this.profileImg);}
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/account/update',formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.resetForm()
              this.$store.dispatch("fetchActiveUser");
            }
        })
      })
    },
    patchUser(){
      this.data = this.$filters.patchUpdatables(this.data, this.activeUser)
    },
    fileUploadReader(input) {
      if (input.target.files && input.target.files[0]) {
        this.profileImg = input.target.files[0]
      }
    },
    resetForm() {
      this.profileImg = null
    },
  },
  created(){
    this.patchUser()
  },
}
</script>
