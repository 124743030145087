
<template>
  <div id="user-edit-tab-info">
    <h6 class="mb-3 text-uppercase bg-light p-2">
      <i class="mdi mdi-account-circle mr-1"></i> Other Info
    </h6>
    <div class="row gy-3 gx-3">
      
      <div class="col-md-6">
        <div class="form-group">
          <label>Country</label>
          <multiselect 
          mode="single"
          searchable
          valueProp="id"
          trackBy="name" label="name"
          :options="countries" 
          v-model="data.country_id" placeholder="--select--"></multiselect>
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label" for="inputMobile">Mobile</label>
        <div class="input-group mb-3">
          <span class="input-group-text"> 
            <img v-if="selectedCountry" width="20" class="me-1" :src="absoluteUrl(`/images/flags/${selectedCountry.icon}`)" />
            <span>{{selectedCountry ? selectedCountry.phone_code : '+xxx'}}</span>
          </span>
          <input id="inputMobile" v-maska data-maska="(###) ###-####" autocomplete="false" type="text" class="form-control" v-model="data.mobile"
            :class="{ 'is-invalid': v$.data.mobile.$error  }" placeholder="mobile" name="mobile" />
        </div>
        <div v-if="v$.data.mobile.$error"
          class="invalid-feedback text-danger">
          <span v-if="v$.data.mobile.required.$message">
            {{ v$.data.mobile.required.$message }}</span>
        </div>
      </div>
      <div class="col-12">
        <label for="streetAddress" class="form-label">Street address</label>
        <input v-model="data.address" type="text" class="form-control"  name="address"
          :class="{ 'is-invalid': v$.data.address.$error  }" id="streetAddress"
          placeholder="Street address">
        <div v-for="(item, index) in v$.data.address.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div> 
      </div>
      <div class="col-md-6">
        <label for="city" class="form-label">City</label>
        <input v-model="data.city" type="text" class="form-control" name="city"
          :class="{ 'is-invalid': v$.data.city.$error  }" id="city" placeholder="City">
        <div v-for="(item, index) in v$.data.city.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div> 
      </div>
      <div class="col-md-6">
        <label for="state" class="form-label">State</label>
        <input v-model="data.state" type="text" class="form-control" name="state"
          :class="{ 'is-invalid': v$.data.state.$error  }" id="state" placeholder="State">
        <div v-for="(item, index) in v$.data.state.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <label for="zip" class="form-label">Zip</label>
        <input v-model="data.zip" type="text" class="form-control"  name="zip"
          :class="{ 'is-invalid': v$.data.zip.$error  }" id="zip" placeholder="Zip">
        <div v-for="(item, index) in v$.data.zip.$errors"
          :key="index" class="invalid-feedback">
          <span v-if="item.$message">{{ item.$message }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="d-block">Date of Birth</label>
          <flat-pickr
            v-model="data.dob"
            :config="{wrap: true, 
              altFormat: 'M j, Y',
              altInput: true,
              dateFormat: 'd M, Y',
              maxDate: new Date()
            }"
            placeholder="Select date"
            class="form-control flatpickr-input"
          ></flat-pickr>
        </div>
      </div>
      <div class="col-12">
        <label for="userbio">About</label>
        <textarea id="userbio"  v-model="data.about" class="form-control"
          rows="3" placeholder="Write something..." name="about"></textarea>
      </div>
    </div>
    <div class="row mt-3 justify-content-end">
      <div class="col-12">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser()" class="btn btn-primary mt-2">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import Multiselect from '@vueform/multiselect'

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    flatPickr,
    Multiselect,
  },
  data() {
    return {
      data:{
        dob: null,
        mobile: "",
        country_id: null,
        address: "",
				city: "",
				state: "",
				zip: "",
        about: "",
      },
    }
  },
  validations() {
    return {
      data:{
        dob: {
          required: required,
        },
        mobile: {
          required: required,
        },
        country_id: {
          required: required,
        },
        address: {
          required: required,
        },
        city: {
          required: required,
        },
        state: {
          required: required,
        },
        zip: {
          required: required,
        }
      }
    }
  },
  computed: {
    countries(){
      return this.$store.state.countries;
    },
    activeUser(){
      return this.$store.state.activeUser
    },
    selectedCountry(){
      if(!this.data.country_id) return null
      const country = this.countries.find(i => i.id ==  this.data.country_id)
      return country ? country : null;
    }
  },
  methods: {
    updateUser(){
      this.v$.$validate().then(result => {
        if (!result){ this.alertError("Fill all fields"); return}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/account/update-info',formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.$store.dispatch("fetchActiveUser");
          }
        })
      })
    },
    patchUser(){
      const address = this.activeUser.address || {}

      this.data = this.$filters.patchUpdatables(this.data, {...this.activeUser, ...address})
      this.data.dob = this.data.dob ? new Date(this.data.dob) : null
    }
  },
  created(){
    this.patchUser()
  },
}
</script>
